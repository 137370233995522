"use strict";
import { UniverseChainId } from "uniswap/src/types/chains";
const BLOCK_EXPLORER_PREFIXES = {
  [UniverseChainId.Mainnet]: "https://etherscan.io",
  [UniverseChainId.Goerli]: "https://goerli.etherscan.io",
  [UniverseChainId.Sepolia]: "https://sepolia.etherscan.io",
  [UniverseChainId.ArbitrumOne]: "https://arbiscan.io",
  [UniverseChainId.ArbitrumGoerli]: "https://goerli.arbiscan.io",
  [UniverseChainId.Optimism]: "https://optimistic.etherscan.io",
  [UniverseChainId.OptimismGoerli]: "https://goerli-optimism.etherscan.io",
  [UniverseChainId.Polygon]: "https://polygonscan.com",
  [UniverseChainId.PolygonMumbai]: "https://mumbai.polygonscan.com",
  [UniverseChainId.Celo]: "https://celoscan.io",
  [UniverseChainId.CeloAlfajores]: "https://alfajores-blockscout.celo-testnet.org",
  [UniverseChainId.Bnb]: "https://bscscan.com",
  [UniverseChainId.Avalanche]: "https://snowtrace.io",
  [UniverseChainId.Base]: "https://basescan.org",
  [UniverseChainId.Blast]: "https://blastscan.io",
  [UniverseChainId.Zora]: "https://zora.superscan.network",
  [UniverseChainId.Zksync]: "https://explorer.zksync.io",
  [UniverseChainId.AbstractTestnet]: "https://explorer.testnet.abs.xyz",
  [UniverseChainId.Zero]: "https://zero-network.calderaexplorer.xyz",
  [UniverseChainId.BOB]: "https://explorer.gobob.xyz",
  [UniverseChainId.CYBER]: "https://cyberscan.co",
  [UniverseChainId.SHAPE]: "https://shapescan.xyz",
  [UniverseChainId.INK]: "",
  // Temporary
  [UniverseChainId.REDSTONE]: "https://explorer.redstone.xyz",
  [UniverseChainId.REDSTONE_GARNET]: "https://explorer.garnetchain.com"
};
export var ExplorerDataType = /* @__PURE__ */ ((ExplorerDataType2) => {
  ExplorerDataType2["TRANSACTION"] = "transaction";
  ExplorerDataType2["TOKEN"] = "token";
  ExplorerDataType2["ADDRESS"] = "address";
  ExplorerDataType2["BLOCK"] = "block";
  ExplorerDataType2["NATIVE"] = "native";
  return ExplorerDataType2;
})(ExplorerDataType || {});
export function getExplorerLink(chainId, data, type) {
  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? "https://etherscan.io";
  switch (type) {
    case "transaction" /* TRANSACTION */:
      return `${prefix}/tx/${data}`;
    case "token" /* TOKEN */:
      return `${prefix}/address/${data}`;
    case "block" /* BLOCK */:
      return `${prefix}/block/${data}`;
    case "address" /* ADDRESS */:
      return `${prefix}/address/${data}`;
    default:
      return `${prefix}`;
  }
}
