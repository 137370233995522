"use strict";
import { Token, WETH9 } from "@uniswap/sdk-core";
import {
  ARB,
  BTC_BSC,
  BUSD_BSC,
  CEUR_CELO,
  CEUR_CELO_ALFAJORES,
  CUSD_CELO,
  CUSD_CELO_ALFAJORES,
  DAI,
  DAI_ARBITRUM_ONE,
  DAI_AVALANCHE,
  DAI_BSC,
  DAI_OPTIMISM,
  DAI_POLYGON,
  ETH_BSC,
  OP,
  PORTAL_ETH_CELO,
  USDC_ARBITRUM,
  USDC_ARBITRUM_GOERLI,
  USDC_AVALANCHE,
  USDC_BASE,
  USDC_BSC,
  USDC_CELO,
  USDC_CELO_ALFAJORES,
  USDC_MAINNET,
  USDC_OPTIMISM,
  USDC_OPTIMISM_GOERLI,
  USDC_POLYGON,
  USDC_POLYGON_MUMBAI,
  USDT,
  USDT_ARBITRUM_ONE,
  USDT_AVALANCHE,
  USDT_BSC,
  USDT_OPTIMISM,
  USDT_POLYGON,
  WBTC,
  WBTC_ARBITRUM_ONE,
  WBTC_CELO,
  WBTC_OPTIMISM,
  WBTC_POLYGON,
  WETH_AVALANCHE,
  WETH_POLYGON,
  WETH_POLYGON_MUMBAI,
  WRAPPED_NATIVE_CURRENCY,
  nativeOnChain
} from "constants/tokens";
import { getNativeLogoURI, getTokenLogoURI } from "lib/hooks/useCurrencyLogoURIs";
import {
  USDC_ABSTRACT_TESTNET,
  USDC_BOB,
  USDC_CYBER,
  USDC_INK,
  USDC_REDSTONE,
  USDC_REDSTONE_GARNET,
  USDC_SHAPE,
  USDC_ZERO,
  USDC_ZKSYNC,
  USDC_ZORA
} from "uniswap/src/constants/tokens";
import { SafetyLevel } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { UniverseChainId } from "uniswap/src/types/chains";
const WRAPPED_NATIVE_CURRENCIES_ONLY = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY).map(([key, value]) => [key, [value]]).filter(Boolean)
);
export function buildCurrencyInfo(commonBase) {
  const logoUrl = commonBase.isNative ? getNativeLogoURI(commonBase.chainId) : getTokenLogoURI(commonBase.address, commonBase.chainId);
  return {
    currency: commonBase,
    logoUrl,
    safetyLevel: SafetyLevel.Verified,
    isSpam: false
  };
}
export const COMMON_BASES = {
  [UniverseChainId.Mainnet]: [
    nativeOnChain(UniverseChainId.Mainnet),
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Mainnet]
  ].map(buildCurrencyInfo),
  [UniverseChainId.Goerli]: [
    nativeOnChain(UniverseChainId.Goerli),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Goerli]
  ].map(buildCurrencyInfo),
  [UniverseChainId.Sepolia]: [
    nativeOnChain(UniverseChainId.Sepolia),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Sepolia]
  ].map(buildCurrencyInfo),
  [UniverseChainId.ArbitrumOne]: [
    nativeOnChain(UniverseChainId.ArbitrumOne),
    ARB,
    DAI_ARBITRUM_ONE,
    USDC_ARBITRUM,
    USDT_ARBITRUM_ONE,
    WBTC_ARBITRUM_ONE,
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.ArbitrumOne]
  ].map(buildCurrencyInfo),
  [UniverseChainId.ArbitrumGoerli]: [
    nativeOnChain(UniverseChainId.ArbitrumGoerli),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.ArbitrumGoerli],
    USDC_ARBITRUM_GOERLI
  ].map(buildCurrencyInfo),
  [UniverseChainId.Optimism]: [
    nativeOnChain(UniverseChainId.Optimism),
    OP,
    DAI_OPTIMISM,
    USDC_OPTIMISM,
    USDT_OPTIMISM,
    WBTC_OPTIMISM,
    WETH9[UniverseChainId.Optimism]
  ].map(buildCurrencyInfo),
  [UniverseChainId.OptimismGoerli]: [nativeOnChain(UniverseChainId.OptimismGoerli), USDC_OPTIMISM_GOERLI].map(
    buildCurrencyInfo
  ),
  [UniverseChainId.Base]: [
    nativeOnChain(UniverseChainId.Base),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Base],
    USDC_BASE
  ].map(buildCurrencyInfo),
  [UniverseChainId.Blast]: [
    nativeOnChain(UniverseChainId.Blast),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Blast]
  ].map(buildCurrencyInfo),
  [UniverseChainId.Polygon]: [
    nativeOnChain(UniverseChainId.Polygon),
    WETH_POLYGON,
    USDC_POLYGON,
    DAI_POLYGON,
    USDT_POLYGON,
    WBTC_POLYGON
  ].map(buildCurrencyInfo),
  [UniverseChainId.PolygonMumbai]: [
    nativeOnChain(UniverseChainId.PolygonMumbai),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.PolygonMumbai],
    USDC_POLYGON_MUMBAI,
    WETH_POLYGON_MUMBAI
  ].map(buildCurrencyInfo),
  [UniverseChainId.Celo]: [
    nativeOnChain(UniverseChainId.Celo),
    CEUR_CELO,
    CUSD_CELO,
    PORTAL_ETH_CELO,
    USDC_CELO,
    WBTC_CELO
  ].map(buildCurrencyInfo),
  [UniverseChainId.CeloAlfajores]: [
    nativeOnChain(UniverseChainId.CeloAlfajores),
    CUSD_CELO_ALFAJORES,
    CEUR_CELO_ALFAJORES,
    USDC_CELO_ALFAJORES
  ].map(buildCurrencyInfo),
  [UniverseChainId.Bnb]: [
    nativeOnChain(UniverseChainId.Bnb),
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    ETH_BSC,
    BTC_BSC,
    BUSD_BSC
  ].map(buildCurrencyInfo),
  [UniverseChainId.Avalanche]: [
    nativeOnChain(UniverseChainId.Avalanche),
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE
  ].map(buildCurrencyInfo),
  [UniverseChainId.Zora]: [
    nativeOnChain(UniverseChainId.Zora),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Zora],
    USDC_ZORA
  ].map(buildCurrencyInfo),
  [UniverseChainId.Zksync]: [
    nativeOnChain(UniverseChainId.Zksync),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Zksync],
    USDC_ZKSYNC
  ].map(buildCurrencyInfo),
  [UniverseChainId.AbstractTestnet]: [
    nativeOnChain(UniverseChainId.AbstractTestnet),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.AbstractTestnet],
    USDC_ABSTRACT_TESTNET
  ].map(buildCurrencyInfo),
  [UniverseChainId.Zero]: [
    nativeOnChain(UniverseChainId.Zero),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.Zero],
    USDC_ZERO
  ].map(buildCurrencyInfo),
  [UniverseChainId.BOB]: [
    nativeOnChain(UniverseChainId.BOB),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.BOB],
    USDC_BOB
  ].map(buildCurrencyInfo),
  [UniverseChainId.CYBER]: [
    nativeOnChain(UniverseChainId.CYBER),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.CYBER],
    USDC_CYBER
  ].map(buildCurrencyInfo),
  [UniverseChainId.SHAPE]: [
    nativeOnChain(UniverseChainId.SHAPE),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.SHAPE],
    USDC_SHAPE
  ].map(buildCurrencyInfo),
  [UniverseChainId.INK]: [
    nativeOnChain(UniverseChainId.INK),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.INK],
    USDC_INK
  ].map(buildCurrencyInfo),
  [UniverseChainId.REDSTONE]: [
    nativeOnChain(UniverseChainId.REDSTONE),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.REDSTONE],
    USDC_REDSTONE
  ].map(buildCurrencyInfo),
  [UniverseChainId.REDSTONE_GARNET]: [
    nativeOnChain(UniverseChainId.REDSTONE_GARNET),
    WRAPPED_NATIVE_CURRENCY[UniverseChainId.REDSTONE_GARNET],
    USDC_REDSTONE_GARNET
  ].map(buildCurrencyInfo)
};
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [UniverseChainId.Mainnet]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[UniverseChainId.Mainnet],
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC
  ],
  [UniverseChainId.Bnb]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[UniverseChainId.Bnb],
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    BTC_BSC,
    BUSD_BSC,
    ETH_BSC
  ],
  [UniverseChainId.Avalanche]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[UniverseChainId.Avalanche],
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE
  ]
};
export const PINNED_PAIRS = {
  [UniverseChainId.Mainnet]: [
    [
      new Token(UniverseChainId.Mainnet, "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643", 8, "cDAI", "Compound Dai"),
      new Token(UniverseChainId.Mainnet, "0x39AA39c021dfbaE8faC545936693aC917d5E7563", 8, "cUSDC", "Compound USD Coin")
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT]
  ]
};
